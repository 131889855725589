import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import css from "../style/singlepage.module.scss"
import Doc from "../../../components/Doc"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ExhibitorList from "../../../components/ExhibitorList"

const IndexPage = data => {
  return (
    <Layout mode={`about`} lang={`en`}>
      <SEO
        ogImagePath={`/assets/img/ogp-exhibition.jpg`}
        title={`TRANS BOOKS DOWNLOADs Real store open for a limited time!`}
        description={`"TRANS BOOKS DOWNLOADs EXHIBITION" will be held at the same gallery in Musashi Koyama from Friday, December 11 to Sunday, December 20, 2020.`}
        lang={`en`}
      />
      <div className={css.wrap}>
        <div className={css.flexSticky}>
          <div className={css.flexSticky__left}>
            <div className={css.flexSticky__left__heading}>
              <div className={`${css.logoBox} fixedRatioBox is-1x1 border`}>
                <video autoPlay={true} muted={true} width="250">
                  <source
                    src="/downloads/assets/images/logo/exhibition2.mp4"
                    type="video/mp4"
                  />
                  動画を再生するにはvideoタグをサポートしたブラウザが必要です。
                </video>
              </div>
            </div>
          </div>
          <div className={css.flexSticky__right}>
            <Doc>
              <p>
                <div
                  style={{
                    border: "2px solid #0000ff",
                    padding: "10px",
                    color: "#0000ff",
                    textAlign: "center",
                  }}
                >
                  This event is over.
                </div>
              </p>
              <h3 style={{ marginTop: 0 }}>
                Real store open for a limited time!
              </h3>
              <p>
                [This is machine translation] <br />
                The steering committee of TRANS BOOKS will hold the TRANS BOOKS
                DOWNLOADs EXHIBITION at the same gallery in Musashi Koyama,
                Tokyo from December 11th to 13th and 18th to 20th, 2020. <br/>
                <small> *Please note that the opening hours are different on Friday and Saturday and Sunday. </small>
              </p>
              <p>
                The "TRANS BOOKS DOWNLOADS EXHIBITION" was organized as a real
                store of the "TRANS BOOKS DOWNLOADS" data bookstore, which is
                now open online.
              </p>
              <p>
                The "TRANS BOOKS DOWNLOADs" is a "data bookstore" that sells
                "data" of various books created by participating authors in its
                online store. The Data Bookstore offers a variety of book data
                that can be "downloaded" to your computer or real-world computer
                for a variety of reading experiences.
              </p>
              <p>
                This time, as a real store, the printed books will be displayed
                at the venue. We will provide a place where you can hold the
                printed books in your hands and enjoy them. We hope you will
                take this opportunity to feel the appeal of the data "books"
                sold at "TRANS BOOKS DOWNLOADs".
              </p>
              <p>
                <small>
                  ※The books exhibited in this exhibition are available for
                  purchase online through the "TRANS BOOKS DOWNLOADs" website.
                  The books printed at the exhibition will not be available for
                  sale.
                </small>
              </p>
              <h3 style={{ marginTop: `80px` }}>Basic Information</h3>
              <dl className={`tiny-dl`}>
                <dt>Event Title</dt>
                <dd>TRANS BOOKS DOWNLOADs</dd>
                <dt>Dates</dt>
                <dd>December 11, 12, 13, 18, 19, 20, 2020</dd>
                <dt>Open hours</dt>
                <dd>
                  Friday: 4:00 pm - 9:00 pm <br />. Saturday, Sunday: 0:00pm to
                  7:00pm
                </dd>
                <dt>Venue</dt>
                <dd>
                  <OutboundLink
                    href={`https://samegallery.com/`}
                    target={`_blank`}
                  >
                    same gallery
                  </OutboundLink>
                  （Musashi Koyama） <br />
                  <OutboundLink
                    href={`https://goo.gl/maps/JC1cKZQs7uc8EsSZ8`}
                    target={`_blank`}
                  >
                    〒142-0063 4-6-7 Ebara, Shinagawa-ku, Tokyo
                  </OutboundLink>
                </dd>
                <dt>Website</dt>
                <dd>https://transbooks.center/</dd>
                <dt>Social Media</dt>
                <dd>
                  <OutboundLink
                    href={`https://www.facebook.com/transbookstrans/`}
                    target={`_blank`}
                  >
                    Facebook
                  </OutboundLink>{" "}
                  /{" "}
                  <OutboundLink
                    href={`https://twitter.com/transbookstrans`}
                    target={`_blank`}
                  >
                    Twitter
                  </OutboundLink>{" "}
                  /{" "}
                  <OutboundLink
                    href={`https://www.instagram.com/transbooks/`}
                    target={`_blank`}
                  >
                    Instagram
                  </OutboundLink>
                </dd>
                <dt>Organizer </dt>
                <dd>
                  TRANS BOOKS Steering Committee <br />
                  (Mio Izawa / Yurie Hata / Shunya Hagiwara / Akiko Saito)
                </dd>
                <dt>Supported by</dt>
                <dd>same gallery</dd>
              </dl>
            </Doc>

            <Doc>
              <h3 style={{ marginTop: `80px` }}>Participating Artist</h3>

              <ExhibitorList lang={`en`} type={`sameGallery`} />
            </Doc>

            <Doc>
              <h3 style={{ marginTop: `80px` }}>Concept</h3>

              <p>
                [This is machine translation] <br />
                TRANS BOOKS has been held since 2017 as a book fair that sells
                any book, electronic, non-electronic or otherwise, regardless of
                the media. It has been held every fall and has sold a wide
                variety of books.
              </p>

              <p>
                Books serve as a bridge between people and information, and
                continue to change shape and expand with the times. We started
                "TRANS BOOKS" to explore more expressions and communications
                that are possible only with "books" to express these times.
              </p>

              <p>
                We think of "books" as a platform that deeply embraces
                expression, and we have been working to create a place where
                people can have fun while experiencing the diversity,
                possibilities, and fun of books and the experiences that
                surround them.
              </p>

              <p>
                As we enter the year 2020, the environment around us has changed
                dramatically. I feel that the relationship between people and
                the media, including books, is changing along with it. I believe
                that the relationship between people and the media, including
                books, is changing, and that we will be able to create a
                relationship that goes beyond simply replacing what we used to
                see and touch in the real world with online and digital media.
              </p>

              <p>
                What possibilities are there for the "book" in such a situation?
                We discussed the relationship between ourselves and the media of
                today, and the theme we came up with was "Download". Download"
                is the act of transferring data on the network to one's computer
                or smartphone, or from the online space to real life.
              </p>

              <p>
                We consider the act of "downloading" as a gateway between the
                online world and our own, and that's why we opened "TRANS BOOKS
                DOWNLOADs". At our bookstore, we aim to provide you with a
                variety of experiences related to books and books that you can
                enjoy today.
              </p>

              <p>
                We are pleased to announce that we have opened the "TRANS BOOKS
                DOWNLOADs EXHIBITION" as a real bookstore. We planned this
                exhibition because we want you to enjoy holding the printed
                books in your hands.
              </p>

              <p>
                At the exhibition, books printed from the data available at
                "TRANS BOOKS DOWNLOADs" will be on display for visitors to take
                in and read and also to purchase books of their interest.
              </p>

              <p>
                We hope you will take this opportunity to feel the appeal of the
                data "books" on sale at "TRANS BOOKS DOWNLOADs".
              </p>
              <p>TRANS BOOKS Steering Committee </p>
              <hr />
              <h3>Contact</h3>
              <p>transbooks.info [at-mark] gmail.com</p>
            </Doc>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default prop => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  artistName
                  alphabetical
                  artistTitle
                }
                fields {
                  slug
                  langKey
                }
                id
              }
            }
          }
        }
      `}
      render={data => <IndexPage site={data}>{prop.children}</IndexPage>}
    />
  )
}
